import { gql } from "apollo-boost";

export const TICKET_STATISTICS_YEAR_QUERY = gql`
  query StoreTicketStatisticsYear($year: Int!) {
    StoreTicketStatisticsYear(year: $year) {
      statisticsMonths {
        Month
        MonthMoney
        MonthProductCount
        MonthCount

        ReadyMoney
        ReadyCount

        ReservationMoney
        ReservationCount

        ExpirationMoney
        ExpirationCount

        CompletionMoney
        CompletionCount

        RefundMoney
        RefundCount
      }
    }
  }
`;

export const TICKET_STATISTICS_YEARORDERRANK_QUERY = gql`
  query StoreTicketStatisticsYearOrderRank($year: Int!) {
    StoreTicketStatisticsYearOrderRank(year: $year) {
      ticketConfig {
        id
      }
      ticketProductYearOrderRanks {
        id
        orderCount
        productName
      }
    }
  }
`;

export const TICKET_STATISTICS_YEARORDERPRODUCTRANK_QUERY = gql`
  query StoreTicketStatisticsYearOrderProductRank($year: Int!) {
    StoreTicketStatisticsYearOrderProductRank(year: $year) {
      ticketConfig {
        id
      }
      ticketProductYearOrderProductRanks {
        id
        orderProductCount
        productName
      }
    }
  }
`;

export const TICKET_STATISTICS_MONTH_QUERY = gql`
  query StoreTicketStatisticsMonth($year: Int!, $month: Int!) {
    StoreTicketStatisticsMonth(year: $year, month: $month) {
      statisticsDays {
        Day
        DayMoney
        DayProductCount
        DayCount

        ReadyMoney
        ReadyCount

        ReservationMoney
        ReservationCount

        ExpirationMoney
        ExpirationCount

        CompletionMoney
        CompletionCount

        RefundMoney
        RefundCount
      }
    }
  }
`;

export const TICKET_STATISTICS_MONTHORDERRANK_QUERY = gql`
  query StoreTicketStatisticsMonthOrderRank($year: Int!, $month: Int!) {
    StoreTicketStatisticsMonthOrderRank(year: $year, month: $month) {
      ticketConfig {
        id
      }
      ticketProductMonthOrderRanks {
        id
        orderCount
        productName
      }
    }
  }
`;

export const TICKET_STATISTICS_MONTHORDERPRODUCTRANK_QUERY = gql`
  query StoreTicketStatisticsMonthOrderProductRank($year: Int!, $month: Int!) {
    StoreTicketStatisticsMonthOrderProductRank(year: $year, month: $month) {
      ticketConfig {
        id
      }
      ticketProductMonthOrderProductRanks {
        id
        orderProductCount
        productName
      }
    }
  }
`;
