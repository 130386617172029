import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { TICKET_STATISTICS_YEARORDERPRODUCTRANK_QUERY } from "./Queries";
import { useQuery } from "react-apollo-hooks";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default withRouter(({ history, match, location, isModal }) => {
  const date = new Date();
  const year = match.params.year
    ? parseInt(match.params.year)
    : date.getFullYear();

  const [monthOrderProductCountTotal, setMonthOrderProductCountTotal] =
    useState(0);

  const { data, loading } = useQuery(
    TICKET_STATISTICS_YEARORDERPRODUCTRANK_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        year: year,
      },
    }
  );

  const modalRef = React.createRef();
  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  useEffect(() => {
    if (!loading) {
      let monthOrderProductCount = 0;
      data.StoreTicketStatisticsYearOrderProductRank.ticketProductYearOrderProductRanks.forEach(
        (item) => {
          monthOrderProductCount += item.orderProductCount;
          setMonthOrderProductCountTotal(monthOrderProductCount);
        }
      );
    }
  }, [data, loading]);

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/ticket/ticketStatistics/year/${year}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/ticket/ticketStatistics/year/${year}`);
          }}
        >
          {year}년 상품순위
        </ModalHeader>
        <ModalBody className="p-0">
          {!loading ? (
            <>
              <div className="m-15">
                <div className="table-responsive ">
                  <table className="table table-bordered table-striped m-b-0 text-center">
                    <thead>
                      <tr>
                        <th>순위</th>
                        <th>상품명</th>
                        <th>주문수량</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>전체</td>
                        <td>-</td>
                        <td>{monthOrderProductCountTotal}개</td>
                      </tr>
                      {data.StoreTicketStatisticsYearOrderProductRank.ticketProductYearOrderProductRanks.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}위</td>
                              <td className="text-left">
                                <a
                                  href={`/ticket/ticketOrderProducts?ticketProduct=${item.id}&startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  {item.productName}
                                </a>
                              </td>
                              <td>{item.orderProductCount}개</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <p className="text-center">등록된 데이터가 없습니다.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  );
});
