import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// 로컬푸드 구독 > 픽업
import DeliveryPlaceList from "../Routes/Store/Delivery/DeliveryPlace/List/index";
import DeliveryPlaceWrite from "../Routes/Store/Delivery/DeliveryPlace/Write/index";
import DeliveryPlaceEdit from "../Routes/Store/Delivery/DeliveryPlace/Edit/index";

// 로컬푸드 구독 > 상품 분류
import DeliveryProductCategoryList from "../Routes/Store/Delivery/DeliveryProduct/Category/List/index";
import DeliveryProductCategoryWrite from "../Routes/Store/Delivery/DeliveryProduct/Category/Write/index";
import DeliveryProductCategoryMove from "../Routes/Store/Delivery/DeliveryProduct/Category/Move/index";
import DeliveryProductCategoryEdit from "../Routes/Store/Delivery/DeliveryProduct/Category/Edit/index";

// 로컬푸드 구독 > 상품
import DeliveryProductList from "../Routes/Store/Delivery/DeliveryProduct/List/index";
import DeliveryProductWrite from "../Routes/Store/Delivery/DeliveryProduct/Write/index";
import DeliveryProductEdit from "../Routes/Store/Delivery/DeliveryProduct/Edit/index";

// 로컬푸드 구독 > 상품 > 옵션
import DeliveryProductOptionWrite from "../Routes/Store/Delivery/DeliveryProduct/Option/Write/index";
import DeliveryProductOptionEdit from "../Routes/Store/Delivery/DeliveryProduct/Option/Edit/index";

// 로컬푸드 구독 > 상품 > 옵션 > 상세
import DeliveryProductOptionDetailWrite from "../Routes/Store/Delivery/DeliveryProduct/Option/Detail/Write/index";
import DeliveryProductOptionDetailEdit from "../Routes/Store/Delivery/DeliveryProduct/Option/Detail/Edit/index";

// 로컬푸드 구독 > 묶음
import DeliverySetList from "../Routes/Store/Delivery/DeliverySet/List/index";
import DeliverySetWrite from "../Routes/Store/Delivery/DeliverySet/Write/index";
import DeliverySetEdit from "../Routes/Store/Delivery/DeliverySet/Edit/index";

// 로컬푸드 구독 > 상품, 묶음 수정 로그
import DeliveryEditLogList from "../Routes/Store/Delivery/DeliveryEditLog/List/index";

// 로컬푸드 구독 > 구독박스
import DeliveryBoxList from "../Routes/Store/Delivery/DeliveryBox/List/index";
import DeliveryBoxDetail from "../Routes/Store/Delivery/DeliveryBox/Detail/index";
import DeliveryBoxDayOfWeek from "../Routes/Store/Delivery/DeliveryBox/DayOfWeek/index";
import DeliveryBoxDayOfWeekPrint from "../Routes/Store/Delivery/DeliveryBox/DayOfWeekPrint/index";

// 로컬푸드 구독 > 구독박스 > 주소
import DeliveryAddressList from "../Routes/Store/Delivery/DeliveryBox/Address/List/index";
import DeliveryAddressWrite from "../Routes/Store/Delivery/DeliveryBox/Address/Write/index";
import DeliveryAddressEdit from "../Routes/Store/Delivery/DeliveryBox/Address/Edit/index";

// 로컬푸드 구독 > 배송
import DeliveryOrderList from "../Routes/Store/Delivery/DeliveryOrder/List/index";
import DeliveryOrderDetail from "../Routes/Store/Delivery/DeliveryOrder/Detail/index";
import DeliveryOrderQRCode from "../Routes/Store/Delivery/DeliveryOrder/QRCode/index";
import DeliveryOrderProductPrint from "../Routes/Store/Delivery/DeliveryOrder/ProductPrint/index";
import DeliveryOrderOrderPrint from "../Routes/Store/Delivery/DeliveryOrder/OrderPrint/index";

// 로컬푸드 구독 > 기사
import DeliveryDeliverList from "../Routes/Store/Delivery/DeliveryDeliver/List/index";

// 로컬푸드 구독 > 통계
import DeliveryStatisticsMonth from "../Routes/Store/Delivery/DeliveryStatistics/Month/index";
import DeliveryStatisticsProduct from "../Routes/Store/Delivery/DeliveryStatistics/Product/index";

// 메인
import TicketDashboard from "../Routes/Store/Ticket/Dashboard/index";

// 선물티켓 > 선물
import TicketProductList from "../Routes/Store/Ticket/TicketConfig/Product/List/index";
import TicketProductWrite from "../Routes/Store/Ticket/TicketConfig/Product/Write/index";
import TicketProductDetail from "../Routes/Store/Ticket/TicketConfig/Product/Detail/index";
import TicketProductEdit from "../Routes/Store/Ticket/TicketConfig/Product/Edit/index";

import TicketProductTicketDateWrite from "../Routes/Store/Ticket/TicketConfig/Product/TicketDate/Write";
import TicketProductTicketDateEdit from "../Routes/Store/Ticket/TicketConfig/Product/TicketDate/Edit/index";
import TicketProductTicketDateTimeWrite from "../Routes/Store/Ticket/TicketConfig/Product/TicketDateTime/Write";
import TicketProductTicketDateTimeEdit from "../Routes/Store/Ticket/TicketConfig/Product/TicketDateTime/Edit/index";

// 선물티켓 > 구매내역
import TicketOrderList from "../Routes/Store/Ticket/TicketOrder/List/index";
import TicketOrderDetail from "../Routes/Store/Ticket/TicketOrder/Detail";

// 선물티켓 > 사용내역
import TicketOrderProductList from "../Routes/Store/Ticket/TicketOrderProduct/List/index";
import TicketOrderProductDetail from "../Routes/Store/Ticket/TicketOrderProduct/Detail/index";

// 선물티켓 > 정산내역
import TicketExchangeList from "../Routes/Store/Ticket/TicketExchange/List/index";
import TicketExchangeDetail from "../Routes/Store/Ticket/TicketExchange/Detail";

// 선물티켓 > 후기
import TicketProductReviewList from "../Routes/Store/Ticket/TicketProductReview/List/index";
import TicketProductReviewDetail from "../Routes/Store/Ticket/TicketProductReview/Detail";

// 선물티켓 > 문의
import TicketProductInquiryList from "../Routes/Store/Ticket/TicketProductInquiry/List/index";
import TicketProductInquiryDetail from "../Routes/Store/Ticket/TicketProductInquiry/Detail";

// 선물티켓 > 통계
import TicketStatisticsYear from "../Routes/Store/Ticket/TicketStatistics/Year";
import TicketStatisticsYearOrderRank from "../Routes/Store/Ticket/TicketStatistics/YearOrderRankModal";
import TicketStatisticsYearOrderProductRank from "../Routes/Store/Ticket/TicketStatistics/YearOrderProductRankModal";
import TicketStatisticsMonth from "../Routes/Store/Ticket/TicketStatistics/Month";
import TicketStatisticsMonthOrderRank from "../Routes/Store/Ticket/TicketStatistics/MonthOrderRankModal";
import TicketStatisticsMonthOrderProductRank from "../Routes/Store/Ticket/TicketStatistics/MonthOrderProductRankModal";
import TicketStatisticsDay from "../Routes/Store/Ticket/TicketStatistics/Day";

const StoreRoutes = ({
  userData,
  history,
  location,
  match,
  isModal,
  previousLocation,
}) => (
  <Switch userData={userData} location={isModal ? previousLocation : location}>
    {/** 픽업 **/}
    <Route
      exact
      path="/delivery/deliveryPlaces"
      component={DeliveryPlaceList}
    />
    <Route
      exact
      path="/delivery/deliveryPlaces/write"
      component={DeliveryPlaceWrite}
    />
    <Route
      exact
      path="/delivery/deliveryPlaces/:id/edit"
      component={DeliveryPlaceEdit}
    />

    {/** 상품 분류 **/}
    <Route
      exact
      path={["/delivery/deliveryProducts/categories"]}
      component={DeliveryProductCategoryList}
    />
    <Route exact path="/delivery/deliveryProducts/categories/write">
      <DeliveryProductCategoryList />
      <DeliveryProductCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/delivery/deliveryProducts/categories/:id/move">
      <DeliveryProductCategoryList />
      <DeliveryProductCategoryMove isModal={isModal} />
    </Route>
    <Route exact path="/delivery/deliveryProducts/categories/:id/edit">
      <DeliveryProductCategoryList />
      <DeliveryProductCategoryEdit isModal={isModal} />
    </Route>

    {/** 상품 **/}
    <Route
      exact
      path="/delivery/deliveryProducts"
      component={DeliveryProductList}
    />
    <Route
      exact
      path="/delivery/deliveryProducts/write"
      component={DeliveryProductWrite}
    />
    <Route
      exact
      path="/delivery/deliveryProducts/:id/edit"
      component={DeliveryProductEdit}
    />
    <Route exact path="/:editType/:id/logs" component={DeliveryEditLogList} />

    {/** 상품 옵션 **/}
    <Route exact path="/delivery/deliveryProducts/:id/options/write">
      <DeliveryProductEdit />
      <DeliveryProductOptionWrite />
    </Route>
    <Route exact path="/delivery/deliveryProducts/:id/options/:option/Edit">
      <DeliveryProductEdit />
      <DeliveryProductOptionEdit />
    </Route>
    {/** 상품 상세 옵션 **/}
    <Route
      exact
      path="/delivery/deliveryProducts/:id/options/:option/details/write"
    >
      <DeliveryProductEdit />
      <DeliveryProductOptionDetailWrite />
    </Route>
    <Route
      exact
      path="/delivery/deliveryProducts/:id/options/:option/details/:detail/edit"
    >
      <DeliveryProductEdit />
      <DeliveryProductOptionDetailEdit />
    </Route>

    {/** 묶음 **/}
    <Route exact path="/delivery/deliverySets" component={DeliverySetList} />
    <Route
      exact
      path="/delivery/deliverySets/write"
      component={DeliverySetWrite}
    />
    <Route
      exact
      path="/delivery/deliverySets/:id/edit"
      component={DeliverySetEdit}
    />

    {/** 구독박스 **/}
    <Route exact path="/delivery/deliveryBoxes" component={DeliveryBoxList} />
    <Route
      exact
      path="/delivery/deliveryBoxes/:id"
      component={DeliveryBoxDetail}
    />
    {/** 구독박스 주소 **/}
    <Route exact path="/delivery/deliveryBoxes/:id/addresses">
      <DeliveryBoxDetail />
      <DeliveryAddressList isModal={isModal} />
    </Route>
    <Route exact path="/delivery/deliveryBoxes/:id/addresses/write">
      <DeliveryBoxDetail />
      <DeliveryAddressWrite isModal={isModal} />
    </Route>
    <Route exact path="/delivery/deliveryBoxes/:id/addresses/:address/edit">
      <DeliveryBoxDetail />
      <DeliveryAddressEdit isModal={isModal} />
    </Route>
    {/** 요일별 주문현황 **/}
    <Route
      exact
      path="/delivery/deliveryBoxesDayOfWeek"
      component={DeliveryBoxDayOfWeek}
    />
    <Route exact path="/delivery/deliveryBoxesDayOfWeek/print">
      <DeliveryBoxDayOfWeek />
      <DeliveryBoxDayOfWeekPrint isModal={isModal} />
    </Route>

    {/** 배송 **/}
    <Route
      exact
      path="/delivery/deliveryOrders"
      component={DeliveryOrderList}
    />
    <Route
      exact
      path="/delivery/deliveryOrders/:id"
      component={DeliveryOrderDetail}
    />
    <Route exact path="/delivery/deliveryOrders/:id/qrcode">
      <DeliveryOrderDetail />
      <DeliveryOrderQRCode isModal={isModal} />
    </Route>
    <Route exact path="/delivery/deliveryOrdersProduct/print">
      <DeliveryOrderList />
      <DeliveryOrderProductPrint isModal={isModal} />
    </Route>
    <Route exact path="/delivery/deliveryOrdersOrder/print">
      <DeliveryOrderList />
      <DeliveryOrderOrderPrint isModal={isModal} />
    </Route>

    {/** 기사 **/}
    <Route
      exact
      path="/delivery/deliveryDelivers"
      component={DeliveryDeliverList}
    />

    {/** 통계 **/}
    <Route
      exact
      path="/delivery/deliveryStatistics/month/:year/:month"
      component={DeliveryStatisticsMonth}
    />
    <Route
      exact
      path="/delivery/deliveryStatistics/product/:year/:month"
      component={DeliveryStatisticsProduct}
    />

    {/* 드림티켓 메인 시작 */}
    <Route exact path="/ticket/dashboard">
      <TicketDashboard userData={userData} />
    </Route>
    {/* 드림티켓 메인 끝 */}

    {/* 드림티켓 시작 */}
    {/** 드림티켓 **/}
    <Route
      exact
      path={["/ticket/ticketConfigs", "/ticket/ticketConfigs/:config/products"]}
      component={TicketProductList}
    />
    <Route exact path="/ticket/ticketConfigs/:config/products/write">
      <TicketProductWrite />
    </Route>
    <Route
      exact
      path="/ticket/ticketConfigs/:config/products/:id"
      component={TicketProductDetail}
    />
    <Route exact path="/ticket/ticketConfigs/:config/products/:id/edit">
      <TicketProductEdit />
    </Route>

    {/** 드림티켓 상품 옵션 **/}
    <Route
      exact
      path="/ticket/ticketConfigs/:config/products/:id/ticketDates/write"
    >
      <TicketProductDetail />
      <TicketProductTicketDateWrite />
    </Route>
    <Route
      exact
      path="/ticket/ticketConfigs/:config/products/:id/ticketDates/:ticketDateId/edit"
    >
      <TicketProductDetail />
      <TicketProductTicketDateEdit />
    </Route>

    {/** 드림티켓 상품 상세 옵션 **/}
    <Route
      exact
      path="/ticket/ticketConfigs/:config/products/:id/ticketDates/:ticketDateId/ticketDateTimes/write"
    >
      <TicketProductDetail />
      <TicketProductTicketDateTimeWrite />
    </Route>
    <Route
      exact
      path="/ticket/ticketConfigs/:config/products/:id/ticketDates/:ticketDateId/ticketDateTimes/:ticketDateTimeId/edit"
    >
      <TicketProductDetail />
      <TicketProductTicketDateTimeEdit />
    </Route>

    {/** 드림티켓 주문내역 **/}
    <Route exact path={"/ticket/ticketOrders"} component={TicketOrderList} />
    <Route
      exact
      path={"/ticket/ticketOrders/:id"}
      component={TicketOrderDetail}
    />

    {/** 드림티켓 티켓내역 **/}
    <Route
      exact
      path={"/ticket/ticketOrderProducts"}
      component={TicketOrderProductList}
    />
    <Route
      exact
      path={"/ticket/ticketOrderProducts/:id"}
      component={TicketOrderProductDetail}
    />

    {/** 선물하기 정산내역 **/}
    <Route
      exact
      path={["/ticket/ticketExchanges", "/ticket/ticketExchanges/year/:year"]}
      component={TicketExchangeList}
    />
    <Route
      exact
      path={"/ticket/ticketExchanges/year/:year/:id"}
      component={TicketExchangeDetail}
    />

    {/** 선물하기 리뷰 **/}
    <Route
      exact
      path={"/ticket/ticketProductReviews"}
      component={TicketProductReviewList}
    />
    <Route
      exact
      path={"/ticket/ticketProductReviews/:id"}
      component={TicketProductReviewDetail}
    />

    {/** 선물하기 문의 **/}
    <Route
      exact
      path={"/ticket/ticketProductInquiries"}
      component={TicketProductInquiryList}
    />
    <Route
      exact
      path={"/ticket/ticketProductInquiries/:id"}
      component={TicketProductInquiryDetail}
    />

    {/** 선물하기 통계 **/}
    <Route
      exact
      path={["/ticket/ticketStatistics", "/ticket/ticketStatistics/year/:year"]}
    >
      <TicketStatisticsYear />
    </Route>
    <Route exact path={"/ticket/ticketStatistics/year/:year/orderRank"}>
      <TicketStatisticsYear />
      <TicketStatisticsYearOrderRank isModal={isModal} />
    </Route>
    <Route exact path={"/ticket/ticketStatistics/year/:year/orderProductRank"}>
      <TicketStatisticsYear />
      <TicketStatisticsYearOrderProductRank isModal={isModal} />
    </Route>
    <Route exact path={"/ticket/ticketStatistics/year/:year/month/:month"}>
      <TicketStatisticsMonth />
    </Route>
    <Route
      exact
      path={"/ticket/ticketStatistics/year/:year/month/:month/orderRank"}
    >
      <TicketStatisticsMonth />
      <TicketStatisticsMonthOrderRank isModal={isModal} />
    </Route>
    <Route
      exact
      path={"/ticket/ticketStatistics/year/:year/month/:month/orderProductRank"}
    >
      <TicketStatisticsMonth />
      <TicketStatisticsMonthOrderProductRank isModal={isModal} />
    </Route>
    <Route
      exact
      path={"/ticket/ticketStatistics/year/:year/month/:month/day:day"}
    >
      <TicketStatisticsDay />
    </Route>
    <Route
      exact
      path={"/ticket/ticketStatistics/year/:year/month/:month/day:day/rank"}
    >
      <TicketStatisticsDay />
      {/* <TicketStatisticsRank isModal={isModal} /> */}
    </Route>
    {/* 드림티켓 끝 */}

    <Redirect from="*" to="/delivery/deliveryPlaces" />
  </Switch>
);

export default StoreRoutes;
