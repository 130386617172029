import React from "react";
import PageRoute from "../../Config/PageRoute";
import { PageSettings } from "../../Config/PageSettings";

const Content = ({ userData, isLoggedIn }) => {
  return (
    <PageSettings.Consumer>
      {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => {
        return (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            <PageRoute userData={userData} isLoggedIn={isLoggedIn} />
          </div>
        );
      }}
    </PageSettings.Consumer>
  );
};
export default Content;
