import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import AdminRoutes from "./AdminRoutes";
import IssuerRoutes from "./IssuerRoutes";
import StoreRoutes from "./StoreRoutes";
import LoggedOutRoutes from "./LoggedOutRoutes";

class PageRoute extends Component {
  constructor(props) {
    super(props);
    this.previousLocation = this.props.location;
  }

  UNSAFE_componentWillUpdate() {
    let { location } = this.props;

    if (!(location.state && location.state.modal)) {
      this.previousLocation = location;
    }
  }

  render() {
    const { userData, isLoggedIn, history, location, match } = this.props;
    const isModal =
      location.state &&
      location.state.modal &&
      this.previousLocation !== location;
    return isLoggedIn ? (
      <Fragment>
        {userData.AdminInfo.role === "Administrator" && (
          <AdminRoutes
            userData={userData}
            history={history}
            location={location}
            match={match}
            isModal={isModal}
            previousLocation={this.previousLocation}
          />
        )}
        {userData.AdminInfo.role === "Issuer" && (
          <IssuerRoutes
            userData={userData}
            history={history}
            location={location}
            match={match}
            isModal={isModal}
            previousLocation={this.previousLocation}
          />
        )}
        {userData.AdminInfo.role === "Store" && (
          <StoreRoutes
            userData={userData}
            history={history}
            location={location}
            match={match}
            isModal={isModal}
            previousLocation={this.previousLocation}
          />
        )}
      </Fragment>
    ) : (
      <LoggedOutRoutes />
    );
  }
}

PageRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default withRouter(PageRoute);
