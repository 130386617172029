import React from "react";
import { graphqlUri } from "../../Config/Env";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    var date = new Date();
    this.state = {
      year: date.getFullYear(),
    };
  }
  render() {
    return (
      <div id="footer" className="footer">
        &copy; {this.state.year} Hanbat Pay - WezONCoop All Rights Reserved (
        {graphqlUri === "http://3.39.203.58:4000" && "Development Version"}
        {graphqlUri === "https://api.hbpay.kr" && "Release Version"})
      </div>
    );
  }
}

export default Footer;
