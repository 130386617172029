import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { PageSettings } from "../../Config/PageSettings";
import useInput from "../../Hooks/useInput";
import { useMutation } from "react-apollo-hooks";
import { LOG_IN, LOCAL_LOG_IN } from "./Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";

export default withRouter(({ history }) => {
  const {
    handleSetPageSidebar,
    handleSetPageHeader,
    handleSetPageContentFullWidth,
  } = useContext(PageSettings);

  const userId = useInput("");
  const password = useInput("");

  const [logInMutation] = useMutation(LOG_IN);
  const [localLogInMutation] = useMutation(LOCAL_LOG_IN);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userId.value === "") toast.error("아이디를 입력해주세요.");
    if (password.value === "") toast.error("비밀번호를 입력해주세요.");

    if (userId.value !== "" && password.value !== "") {
      try {
        const {
          data: { AdminLogIn: token },
        } = await logInMutation({
          variables: {
            userId: userId.value,
            password: password.value,
          },
        });
        if (token !== "" && token !== undefined) {
          localLogInMutation({ variables: { token } });
        } else {
          throw Error();
        }
      } catch (e) {
        console.log(e);
        var error = e.toString();
        error = error.replace("Error: GraphQL error:", "");
        toast.error(`${error}`);
      }
    } else {
      toast.error("아이디 비밀번호를 입력해주세요.");
    }
  };

  useEffect(() => {
    handleSetPageSidebar(false);
    handleSetPageHeader(false);
    handleSetPageContentFullWidth(true);
    return () => {
      handleSetPageSidebar(true);
      handleSetPageHeader(true);
      handleSetPageContentFullWidth(false);
    };
  }, [
    handleSetPageSidebar,
    handleSetPageHeader,
    handleSetPageContentFullWidth,
  ]);

  return (
    <Presenter
      userId={userId}
      password={password}
      handleSubmit={handleSubmit}
    />
  );
});
