import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo-hooks";
import Client from "./Apollo/Client";
import App from "./App";

// css
import "bootstrap/dist/css/bootstrap.css";
import "react-quill/dist/quill.snow.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/react.scss";
import "bootstrap-social/bootstrap-social.css";
import "bootstrap-daterangepicker/daterangepicker.css";

// ========================================

Client.cache.writeData({
  data: {
    isLoggedIn: Boolean(localStorage.getItem("token")) || false
  }
});

ReactDOM.render(
  <ApolloProvider client={Client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
