import { gql } from "apollo-boost";

export const LOG_IN = gql`
  mutation AdminLogIn($userId: String!, $password: String!) {
    AdminLogIn(userId: $userId, password: $password)
  }
`;

export const LOCAL_LOG_IN = gql`
  mutation logUserIn($token: String!) {
    logUserIn(token: $token) @client
  }
`;

export const ADMIN_AUTH_INFO_QUERY = gql`
  mutation AdminAuthInfo($userId: String!, $password: String!) {
    AdminAuthInfo(userId: $userId, password: $password) {
      avatar
      userId
      name
      role
      addCurrency
    }
  }
`;
