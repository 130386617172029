import React from "react";
import { Helmet } from "react-helmet";

export default ({ userId, password, handleSubmit }) => {
  return (
    <>
      <Helmet>
        <title>한밭페이 | 로그인</title>
      </Helmet>
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div
            className="news-image"
            style={{
              backgroundImage: 'URL("../assets/img/admin/login-cover.png")',
              backgroundSize: "cover",
            }}
          ></div>
        </div>

        <div className="right-content">
          <div className="login-header">
            <div className="brand">
              <b>한밭페이</b> 관리자
              <small>지역화폐 호혜거래 시스템</small>
            </div>
          </div>

          <div className="login-content">
            <form className="margin-bottom-0" onSubmit={handleSubmit}>
              <div className="form-group m-b-15">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="아이디 입력"
                  required
                  value={userId.value}
                  onChange={userId.onChange}
                />
              </div>
              <div className="form-group m-b-15">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  placeholder="비밀번호 입력"
                  required
                  value={password.value}
                  onChange={password.onChange}
                />
              </div>
              <div className="login-buttons">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg"
                >
                  로그인
                </button>
              </div>
              <hr />
              <p className="text-center text-grey-darker mb-0">
                © Hanbat Pay - WezONCoop All Rights Reserved.
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
