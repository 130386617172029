import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import SearchProduct from "./SearchProduct";

const Presenter = ({
  history,
  match,
  location,
  page,
  ticketProduct,
  keyword,
  keywordInput,
  blockSize,
  first,
  skip,
  data,
  loading,
  baseUrl,
  handleSearch,
}) => {
  return (
    <>
      <Helmet>
        <title>후기 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림티켓</li>
          <li className="breadcrumb-item active">후기</li>
        </ol>
        <h1 className="page-header">후기</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.StoreTicketProductReviewList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row m-b-15">
                  <div className="col-xs-12 select">
                    <SearchProduct ticketProduct={ticketProduct} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(작성자) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody className="">
                {!loading && data.StoreTicketProductReviewList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "15%" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "15%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">상태</th>
                            <th className="text-center">이미지</th>
                            <th className="text-center">
                              티켓정보 (정가 - 할인가 = 가격)
                            </th>
                            <th className="text-center">후기정보</th>
                            <th className="text-center">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.StoreTicketProductReviewList.ticketProductReviews.map(
                            (item, index) => {
                              const CoverImage =
                                item.ticketProduct.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.ticketProduct.coverImage}`;

                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    {item.commonStatus === "S" && (
                                      <div>
                                        <span className="label label-yellow m-b-5">
                                          댓글대기
                                        </span>
                                      </div>
                                    )}
                                    {item.commonStatus === "C" && (
                                      <div>
                                        <span className="label label-primary m-b-5">
                                          댓글완료
                                        </span>
                                      </div>
                                    )}
                                    {item.commonStatus === "D" && (
                                      <div>
                                        <span className="label label-danger m-b-5">
                                          삭제
                                        </span>
                                      </div>
                                    )}{" "}
                                    {item.isSecret ? (
                                      <div>
                                        <span className="label label-secondary">
                                          비밀글
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="label label-info">
                                          일반글
                                        </span>
                                      </div>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div
                                      className="product-img"
                                      style={{
                                        backgroundImage: `url(${CoverImage})`,
                                      }}
                                    >
                                      <img
                                        src={CoverImage}
                                        alt={item.ticketProduct.name}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      가 맹 점 : {item.ticketProduct.store.name}
                                    </div>
                                    <div>
                                      티 켓 명 :{" "}
                                      <b>
                                        [
                                        {item.ticketProduct.ticketCategory
                                          .parentCategory
                                          ? `${item.ticketProduct.ticketCategory.parentCategory.name} &gt; ${item.ticketProduct.ticketCategory.name}`
                                          : `${item.ticketProduct.ticketCategory.name}`}
                                        ]{item.ticketProduct.name}
                                      </b>
                                    </div>
                                    <div>
                                      가 격 :
                                      {item.ticketProduct.isPay ? (
                                        <>
                                          <NumberFormat
                                            displayType={"text"}
                                            value={
                                              item.ticketOrderProduct.price
                                            }
                                            thousandSeparator={true}
                                            suffix={"드림"}
                                          />{" "}
                                          -{" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            value={
                                              item.ticketOrderProduct.isDiscount
                                                ? item.ticketOrderProduct
                                                    .discountPrice
                                                : 0
                                            }
                                            thousandSeparator={true}
                                            suffix={"드림"}
                                          />{" "}
                                          ={" "}
                                          <b>
                                            <NumberFormat
                                              displayType={"text"}
                                              value={
                                                item.ticketOrderProduct
                                                  .totalPrice
                                              }
                                              thousandSeparator={true}
                                              suffix={"드림"}
                                            />
                                          </b>
                                        </>
                                      ) : (
                                        " 무료"
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      별 점 : <b>{item.score}점</b>
                                    </div>
                                    <div
                                      style={{
                                        width: "300px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        display: "block",
                                        fontWeight: "400",
                                        letterSpacing: "-.5px",
                                      }}
                                    >
                                      후 기 : {item.contents}
                                    </div>
                                    <div>
                                      작 성 자 : {item.user.name}(
                                      {item.user.userId})
                                    </div>
                                    <div>
                                      작성일시 :{" "}
                                      <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                        {item.createdAt}
                                      </ReactMoment>
                                    </div>
                                    {item.isComment && (
                                      <div
                                        style={{
                                          width: "300px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                          fontWeight: "400",
                                          letterSpacing: "-.5px",
                                        }}
                                      >
                                        가맹점 댓글 : {item.comment}
                                      </div>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      <Link
                                        to={`/ticket/ticketProductReviews/${item.id}${location.search}`}
                                        className="btn btn-white btn-xs text-primary m-b-5"
                                      >
                                        <i className="fa fa-reply fa-fw" /> 댓글
                                      </Link>
                                    </div>{" "}
                                    {/* {item.isSecret ? (
                                      <div>
                                        <button
                                          onClick={() => onSecretoggle(item.id)}
                                          className="btn btn-white btn-xs text-info m-b-5"
                                        >
                                          <i className="fa fa-unlock fa-fw"></i>{" "}
                                          일반글
                                        </button>
                                      </div>
                                    ) : (
                                      <div>
                                        <button
                                          onClick={() => onSecretoggle(item.id)}
                                          className="btn btn-white btn-xs text-secondary m-b-5"
                                        >
                                          <i className="fa fa-lock fa-fw"></i>{" "}
                                          비밀글
                                        </button>
                                      </div>
                                    )}{" "} */}
                                    {/* <div>
                                      <button
                                        className="btn btn-white btn-xs text-danger"
                                        onClick={() => onDelete(item.id)}
                                      >
                                        <i className="fa fa-trash fa-fw"></i>{" "}
                                        삭제
                                      </button>
                                    </div> */}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.StoreTicketProductReviewList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.StoreTicketProductReviewList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Presenter;
