import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
// import { HashRouter as Router } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PageSettings } from "./Config/PageSettings";

import Header from "./Components/Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
import Content from "./Components/Content/Content";
import Footer from "./Components/Footer/Footer";
import FloatSubMenu from "./Components/FloatSubMenu/FloatSubMenu";

const QUERY = gql`
  {
    isLoggedIn @client
  }
`;

const USER_INFO_QUERY = gql`
  {
    AdminInfo {
      avatar
      userId
      name
      role
      addCurrency
    }
  }
`;

const App = () => {
  const {
    data: { isLoggedIn },
  } = useQuery(QUERY);

  const { data, loading } = useQuery(USER_INFO_QUERY);

  var floatSubMenuRemove;
  var floatSubMenuCalculate;
  var floatSubMenuRemoveTime = 250;

  const [pageHeader, setPageHeader] = useState(true);
  const [pageheaderMegaMenu, setPageheaderMegaMenu] = useState(false);
  const [pageHeaderLanguageBar, setPageHeaderLanguageBar] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const handleSetPageHeader = (value) => {
    setPageHeader(value);
  };
  const handleSetPageHeaderMegaMenu = (value) => {
    setPageheaderMegaMenu(value);
  };
  const handleSetPageHeaderLanguageBar = (value) => {
    setPageHeaderLanguageBar(value);
  };

  const [pageSidebar, setPageSidebar] = useState(true);
  const [pageSidebarWide, setPageSidebarWide] = useState(true);
  const [pageSidebarLight, setPageSidebarLight] = useState(false);
  const [pageSidebarMinify, setPageSidebarMinify] = useState(false);
  const [pageSidebarToggled, setPageSidebarToggled] = useState(false);
  const [pageSidebarTransparent, setPageSidebarTransparent] = useState(false);
  const handleSetPageSidebar = (value) => {
    setPageSidebar(value);
  };
  const handleSetPageSidebarWide = (value) => {
    setPageSidebarWide(value);
  };
  const handleSetPageSidebarLight = (value) => {
    setPageSidebarLight(value);
  };
  const handleSetPageSidebarMinified = (value) => {
    setPageSidebarMinify(value);
  };
  const handleSetPageSidebarTransparent = (value) => {
    setPageSidebarTransparent(value);
  };
  const handleSidebarOnMouseOut = (e) => {
    if (pageSidebarMinify) {
      floatSubMenuRemove = setTimeout(() => {
        setPageFloatSubMenuActive(false);
      }, floatSubMenuRemoveTime);
    }
  };
  const handleSidebarOnMouseOver = (e, menu) => {
    if (pageSidebarMinify) {
      if (menu.children) {
        var left =
          document.getElementById("sidebar").offsetWidth +
          document.getElementById("sidebar").offsetLeft +
          "px";

        clearTimeout(floatSubMenuRemove);
        clearTimeout(floatSubMenuCalculate);

        setPageFloatSubMenu(menu);
        setPageFloatSubMenuActive(true);
        setPageFloatSubMenuLeft(left);

        var offset = e.currentTarget.offsetParent.getBoundingClientRect();

        floatSubMenuCalculate = setTimeout(() => {
          var targetTop = offset.top;
          var windowHeight = window.innerHeight;
          var targetHeight = document.querySelector(
            ".float-sub-menu-container"
          ).offsetHeight;
          var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

          if (windowHeight - targetTop > targetHeight) {
            top = offset.top + "px";
            bottom = "auto";
            arrowTop = "20px";
            arrowBottom = "auto";
            lineTop = "20px";
            lineBottom = "auto";
          } else {
            var aBottom = windowHeight - targetTop - 21;
            top = "auto";
            bottom = "0";
            arrowTop = "auto";
            arrowBottom = aBottom + "px";
            lineTop = "20px";
            lineBottom = aBottom + "px";
          }

          setPageFloatSubMenuTop(top);
          setPageFloatSubMenuBottom(bottom);
          setPageFloatSubMenuLineTop(lineTop);
          setPageFloatSubMenuLineBottom(lineBottom);
          setPageFloatSubMenuArrowTop(arrowTop);
          setPageFloatSubMenuArrowBottom(arrowBottom);
          setPageFloatSubMenuOffset(offset);
        }, 0);
      } else {
        floatSubMenuRemove = setTimeout(() => {
          setPageFloatSubMenu("");
          setPageFloatSubMenuActive(false);
        }, floatSubMenuRemoveTime);
      }
    }
  };
  const toggleSidebarMinify = (e) => {
    e.preventDefault();
    if (pageSidebarMinify) {
      setPageFloatSubMenuActive(false);
    }
    setPageSidebarMinify(!pageSidebarMinify);
  };
  const toggleMobileSidebar = () => {
    setPageSidebarToggled(!pageSidebarToggled);
  };

  const [pageFloatSubMenuActive, setPageFloatSubMenuActive] = useState(false);
  const [pageFloatSubMenu, setPageFloatSubMenu] = useState("");
  const [pageFloatSubMenuTop, setPageFloatSubMenuTop] = useState("auto");
  const [pageFloatSubMenuLeft, setPageFloatSubMenuLeft] = useState("auto");
  const [pageFloatSubMenuBottom, setPageFloatSubMenuBottom] = useState("auto");
  const [pageFloatSubMenuLineTop, setPageFloatSubMenuLineTop] =
    useState("auto");
  const [pageFloatSubMenuLineBottom, setPageFloatSubMenuLineBottom] =
    useState("auto");
  const [pageFloatSubMenuArrowTop, setPageFloatSubMenuArrowTop] =
    useState("auto");
  const [pageFloatSubMenuArrowBottom, setPageFloatSubMenuArrowBottom] =
    useState("auto");
  const [pageFloatSubMenuOffset, setPageFloatSubMenuOffset] = useState("");

  const handleFloatSubMenuOnMouseOver = (e) => {
    clearTimeout(floatSubMenuRemove);
    clearTimeout(floatSubMenuCalculate);
  };
  const handleFloatSubMenuOnMouseOut = (e) => {
    floatSubMenuRemove = setTimeout(() => {
      setPageFloatSubMenuActive(false);
    }, floatSubMenuRemoveTime);
  };
  const handleFloatSubMenuClick = () => {
    if (pageSidebarMinify) {
      const windowHeight = window.innerHeight;
      const targetHeight =
        document.getElementById("float-sub-menu").offsetHeight;
      const targetTop = pageFloatSubMenuOffset.top;
      const top = windowHeight - targetTop > targetHeight ? targetTop : "auto";
      const left =
        pageFloatSubMenuOffset.left +
        document.getElementById("sidebar").offsetWidth +
        "px";
      const bottom = windowHeight - targetTop > targetHeight ? "auto" : "0";
      const arrowTop =
        windowHeight - targetTop > targetHeight ? "20px" : "auto";
      const arrowBottom =
        windowHeight - targetTop > targetHeight
          ? "auto"
          : windowHeight - targetTop - 21 + "px";
      const lineTop = windowHeight - targetTop > targetHeight ? "20px" : "auto";
      const lineBottom =
        windowHeight - targetTop > targetHeight
          ? "auto"
          : windowHeight - targetTop - 21 + "px";

      setPageFloatSubMenuTop(top);
      setPageFloatSubMenuLeft(left);
      setPageFloatSubMenuBottom(bottom);
      setPageFloatSubMenuLineTop(lineTop);
      setPageFloatSubMenuLineBottom(lineBottom);
      setPageFloatSubMenuArrowTop(arrowTop);
      setPageFloatSubMenuArrowBottom(arrowBottom);
    }
  };

  const [pageContent, setPageContent] = useState(true);
  const [pageContentClass, setPageContentClass] = useState("");
  const [pageContentFullHeight, setPageContentFullHeight] = useState(false);
  const [pageContentFullWidth, setPageContentFullWidth] = useState(false);
  const [pageContentInverseMode, setPageContentInverseMode] = useState(false);
  const handleSetPageContent = (value) => {
    setPageContent(value);
  };
  const handleSetPageContentClass = (value) => {
    setPageContentClass(value);
  };
  const handleSetPageContentFullHeight = (value) => {
    setPageContentFullHeight(value);
  };
  const handleSetPageContentFullWidth = (value) => {
    setPageContentFullWidth(value);
  };
  const handleSetPageContentInverseMode = (value) => {
    setPageContentInverseMode(value);
  };

  const [pageFooter, setPageFooter] = useState(true);
  const handleSetPageFooter = (value) => {
    setPageFooter(value);
  };

  const [pageTopMenu, setPageTopMenu] = useState(false);
  const [pageMobileTopMenu, setPageMobileTopMenu] = useState(false);
  const toggleMobileTopMenu = (e) => {
    e.preventDefault();
    setPageMobileTopMenu(!pageMobileTopMenu);
  };
  const handleSetPageTopMenu = (value) => {
    setPageTopMenu(value);
  };

  const [pageTwoSidebar, setPageTwoSidebar] = useState(false);
  const handleSetPageTwoSidebar = (value) => {
    setPageTwoSidebar(value);
  };

  const [pageRightSidebar, setPageRightSidebar] = useState(false);
  // const [pageRightSidebarToggled, setPageRightSidebarToggled] = useState(true);
  const [pageMobileRightSidebarToggled, setPageMobileRightSidebarToggled] =
    useState(false);
  const toggleMobileRightSidebar = (e) => {
    e.preventDefault();
    setPageMobileRightSidebarToggled(!pageMobileRightSidebarToggled);
  };
  const handleSetPageRightSidebar = (value) => {
    setPageRightSidebar(value);
  };

  const handleSetBodyWhiteBg = (value) => {
    if (value === true) {
      document.body.classList.add("bg-white");
    } else {
      document.body.classList.remove("bg-white");
    }
  };
  const handleSetPageBoxedLayout = (value) => {
    if (value === true) {
      document.body.classList.add("boxed-layout");
    } else {
      document.body.classList.remove("boxed-layout");
    }
  };

  const state = {
    pageHeader: pageHeader,
    pageheaderMegaMenu: pageheaderMegaMenu,
    pageHeaderLanguageBar: pageHeaderLanguageBar,
    hasScroll: hasScroll,
    handleSetPageHeader: handleSetPageHeader,
    handleSetPageHeaderLanguageBar: handleSetPageHeaderLanguageBar,
    handleSetPageHeaderMegaMenu: handleSetPageHeaderMegaMenu,

    pageSidebar: pageSidebar,
    pageSidebarWide: pageSidebarWide,
    pageSidebarLight: pageSidebarLight,
    pageSidebarMinify: pageSidebarMinify,
    pageSidebarToggled: pageSidebarToggled,
    pageSidebarTransparent: pageSidebarTransparent,
    handleSetPageSidebar: handleSetPageSidebar,
    handleSetPageSidebarWide: handleSetPageSidebarWide,
    handleSetPageSidebarLight: handleSetPageSidebarLight,
    handleSetPageSidebarMinified: handleSetPageSidebarMinified,
    handleSetPageSidebarTransparent: handleSetPageSidebarTransparent,
    handleSidebarOnMouseOut: handleSidebarOnMouseOut,
    handleSidebarOnMouseOver: handleSidebarOnMouseOver,
    toggleSidebarMinify: toggleSidebarMinify,
    toggleMobileSidebar: toggleMobileSidebar,

    pageFloatSubMenuActive: pageFloatSubMenuActive,
    pageFloatSubMenu: pageFloatSubMenu,
    pageFloatSubMenuTop: pageFloatSubMenuTop,
    pageFloatSubMenuLeft: pageFloatSubMenuLeft,
    pageFloatSubMenuBottom: pageFloatSubMenuBottom,
    pageFloatSubMenuLineTop: pageFloatSubMenuLineTop,
    pageFloatSubMenuLineBottom: pageFloatSubMenuLineBottom,
    pageFloatSubMenuArrowTop: pageFloatSubMenuArrowTop,
    pageFloatSubMenuArrowBottom: pageFloatSubMenuArrowBottom,
    pageFloatSubMenuOffset: pageFloatSubMenuOffset,
    handleFloatSubMenuOnMouseOver: handleFloatSubMenuOnMouseOver,
    handleFloatSubMenuOnMouseOut: handleFloatSubMenuOnMouseOut,
    handleFloatSubMenuClick: handleFloatSubMenuClick,

    pageContent: pageContent,
    pageContentClass: pageContentClass,
    pageContentFullHeight: pageContentFullHeight,
    pageContentFullWidth: pageContentFullWidth,
    pageContentInverseMode: pageContentInverseMode,
    handleSetPageContent: handleSetPageContent,
    handleSetPageContentClass: handleSetPageContentClass,
    handleSetPageContentFullHeight: handleSetPageContentFullHeight,
    handleSetPageContentFullWidth: handleSetPageContentFullWidth,
    handleSetPageContentInverseMode: handleSetPageContentInverseMode,

    pageFooter: pageFooter,
    handleSetPageFooter: handleSetPageFooter,

    pageTopMenu: pageTopMenu,
    pageMobileTopMenu: pageMobileTopMenu,
    toggleMobileTopMenu: toggleMobileTopMenu,
    handleSetPageTopMenu: handleSetPageTopMenu,

    pageTwoSidebar: pageTwoSidebar,
    handleSetPageTwoSidebar: handleSetPageTwoSidebar,

    pageRightSidebar: pageRightSidebar,
    // pageRightSidebarToggled: pageRightSidebarToggled,
    pageMobileRightSidebarToggled: pageMobileRightSidebarToggled,
    toggleMobileRightSidebar: toggleMobileRightSidebar,
    handleSetPageRightSidebar: handleSetPageRightSidebar,

    handleSetBodyWhiteBg: handleSetBodyWhiteBg,
    handleSetPageBoxedLayout: handleSetPageBoxedLayout,
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasScroll(true);
    } else {
      setHasScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!loading) {
    return (
      <PageSettings.Provider value={state}>
        <Router>
          <div
            className={
              "fade page-sidebar-fixed show page-container " +
              (state.pageHeader ? "page-header-fixed " : "") +
              (state.pageSidebar ? "" : "page-without-sidebar ") +
              (state.pageRightSidebar ? "page-with-right-sidebar " : "") +
              (state.pageSidebarWide ? "page-with-wide-sidebar " : "") +
              (state.pageSidebarLight ? "page-with-light-sidebar " : "") +
              (state.pageSidebarMinify ? "page-sidebar-minified " : "") +
              (state.pageSidebarToggled ? "page-sidebar-toggled " : "") +
              (state.pageTopMenu ? "page-with-top-menu " : "") +
              (state.pageContentFullHeight ? "page-content-full-height " : "") +
              (state.pageTwoSidebar ? "page-with-two-sidebar " : "") +
              (state.pageMobileRightSidebarToggled
                ? "page-right-sidebar-toggled "
                : "") +
              (state.hasScroll ? "has-scroll " : "")
            }
          >
            {state.pageHeader && isLoggedIn && data && (
              <Header userData={data} />
            )}
            {state.pageSidebar && isLoggedIn && data && (
              <Sidebar userData={data} />
            )}
            {state.pageContent && (
              <Content userData={data} isLoggedIn={isLoggedIn} />
            )}
            {state.pageFooter && isLoggedIn && <Footer />}
            <FloatSubMenu />
          </div>
        </Router>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      </PageSettings.Provider>
    );
  } else {
    return <></>;
  }
};
export default App;
