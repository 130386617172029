import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "../Routes/Login/index";

const LoggedOutRoutes = () => (
  <Switch>
    <Route exact Path="/login" component={Login} />
    <Redirect from="*" to="/" />
  </Switch>
);

export default LoggedOutRoutes;
