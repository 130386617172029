import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_INQUIRY_DETAIL_QUERY,
  TICKET_PRODUCT_INQUIRY_REPLY_QUERY,
} from "./Queries";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { graphqlUri } from "../../../../Config/Env";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import renderHTML from "react-render-html";

export default withRouter(({ history, location, match }) => {
  const { register, handleSubmit, errors } = useForm();
  const id = match.params.id;

  const { data, loading } = useQuery(TICKET_PRODUCT_INQUIRY_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id },
  });

  const [ticketProductInquiryReplyMutation] = useMutation(
    TICKET_PRODUCT_INQUIRY_REPLY_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_INQUIRY_DETAIL_QUERY,
          variables: { id },
        },
      ],
    }
  );

  const onSubmit = async (data) => {
    try {
      const {
        data: { StoreTicketProductInquiryReply },
      } = await ticketProductInquiryReplyMutation({
        variables: {
          id,
          answer: data.answer,
          isPush: data.isPush,
        },
      });
      if (StoreTicketProductInquiryReply) {
        toast.success("처리 되었습니다.");
        history.push(`/ticket/ticketProductInquiries${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  if (!loading) {
    const {
      StoreTicketProductInquiryDetail: {
        ticketProduct,
        user,
        commonStatus,
        isSecret,
        question,
        answer,
        createdAt,
        // updatedAt,
      },
    } = data;

    const CoverImage =
      ticketProduct.coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${ticketProduct.coverImage}`;

    return (
      <>
        <Helmet>
          <title>문의 | 한밭페이</title>
        </Helmet>
        <div>
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item ">드림티켓</li>
              <li className="breadcrumb-item active">문의</li>
            </ol>
            <h1 className="page-header">문의</h1>
            <div className="row m-b-15">
              <div className="col-md-6 text-left">
                <Link
                  className="btn btn-white"
                  to={`/ticket/ticketProductInquiries`}
                >
                  <i className="fa fa-list fa-fw"></i> {""}
                  목록
                </Link>
              </div>
              <div className="col-md-6 text-right">
                {/* {isSecret ? (
                  <button
                    type="button"
                    className="btn btn-white text-info"
                    onClick={() => onSecretoggle(id)}
                  >
                    <i className="fa fa-unlock fa-fw"></i> 일반글
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-white text-secondary"
                    onClick={() => onSecretoggle(id)}
                  >
                    <i className="fa fa-lock fa-fw"></i> 비밀글
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-white text-danger m-l-5"
                  onClick={() => onDelete(id)}
                >
                  <i className="fa fa-trash fa-fw"></i> 삭제
                </button> */}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>질문</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="invoice order-product">
                      <div className="invoice-company text-inverse f-w-600">
                        {ticketProduct.store.name}
                      </div>
                      <div className="underline p-b-15 m-b-15">
                        <ul className="title-info-ul">
                          <li>
                            {commonStatus === "S" && (
                              <span className="label label-yellow">
                                댓글대기
                              </span>
                            )}
                            {commonStatus === "C" && (
                              <span className="label label-primary">
                                댓글완료
                              </span>
                            )}
                            {commonStatus === "D" && (
                              <span className="label label-danger">삭제</span>
                            )}{" "}
                            {isSecret ? (
                              <span className="label label-secondary">
                                비밀글
                              </span>
                            ) : (
                              <span className="label label-info ">일반글</span>
                            )}
                          </li>
                          <li>
                            <span>{user.name}</span>
                          </li>
                          <li>
                            <span className="m-l-5">
                              <i className="far fa-clock "></i>
                              <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                {createdAt}
                              </ReactMoment>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="order-product-list m-0">
                        <div className="img">
                          <img src={CoverImage} alt={ticketProduct.name} />
                        </div>
                        <div className="cont">
                          <div className="f-s-14">
                            가 맹 점 : {ticketProduct.store.name}
                          </div>
                          <strong>
                            <Link
                              to={`/ticket/ticketConfigs/${ticketProduct.ticketConfig.id}/products/${ticketProduct.id}`}
                            >
                              티 켓 명 : [
                              {ticketProduct.ticketCategory.parentCategory
                                ? `${ticketProduct.ticketCategory.parentCategory.name} &gt; ${ticketProduct.ticketCategory.name}`
                                : `${ticketProduct.ticketCategory.name}`}
                              ]{ticketProduct.name}
                            </Link>
                          </strong>
                          <div className="f-s-14">
                            가 격 :{" "}
                            <NumberFormat
                              displayType={"text"}
                              value={ticketProduct.price}
                              thousandSeparator={true}
                              suffix={"드림"}
                            />{" "}
                            -{" "}
                            <NumberFormat
                              displayType={"text"}
                              value={
                                ticketProduct.isDiscount
                                  ? ticketProduct.discountPrice
                                  : 0
                              }
                              thousandSeparator={true}
                              suffix={"드림"}
                            />{" "}
                            ={" "}
                            <b>
                              <NumberFormat
                                displayType={"text"}
                                value={ticketProduct.totalPrice}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>질문&amp;답변</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="form-group ">
                        <label className="col-form-label col-md-12">
                          <strong className="f-s-14">
                            <i className="far fa-comment fa-fw"></i> 질 문
                          </strong>
                        </label>
                        <div className="col-md-12 bg-light p-10">
                          <div className="bg-white rounded p-10">
                            {renderHTML(question)}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label col-md-12">
                          <strong className="f-s-14">
                            <i className="far fa-comments fa-fw"></i> 답 변
                          </strong>
                        </label>
                        <div className="col-md-12 bg-light p-10">
                          <div className="bg-white rounded">
                            <textarea
                              name="answer"
                              className="form-control"
                              rows="5"
                              placeholder="문의하기 답변을 입력해주세요."
                              ref={register({
                                required: "문의하기 답변을 입력해주세요.",
                              })}
                            >
                              {answer}
                            </textarea>
                          </div>
                          {errors.answer && (
                            <p className="m-t-5 m-b-0 text-danger">
                              * {errors.answer.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">알림</label>
                        <div className="col-md-10">
                          <div className="checkbox checkbox-css">
                            <input
                              type="checkbox"
                              id="isPush"
                              name="isPush"
                              value={true}
                              ref={register()}
                            />
                            <label htmlFor="isPush">&nbsp;</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 text-left">
                <Link
                  className="btn btn-white"
                  to={`/ticket/ticketProductInquiries`}
                >
                  <i className="fa fa-list fa-fw"></i> {""}
                  목록
                </Link>
              </div>
              <div className="col-md-4 text-center">
                <button type="submit" className="btn btn-white text-primary">
                  확인
                </button>
              </div>
              <div className="col-md-4 text-right">
                {/* {isSecret ? (
                  <button
                    onClick={() => onSecretoggle(id)}
                    className="btn btn-white text-info"
                  >
                    <i className="fa fa-unlock fa-fw"></i> 일반글
                  </button>
                ) : (
                  <button
                    onClick={() => onSecretoggle(id)}
                    className="btn btn-white text-secondary"
                  >
                    <i className="fa fa-lock fa-fw"></i> 비밀글
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-white text-danger m-l-5"
                  onClick={() => onDelete(id)}
                >
                  <i className="fa fa-trash fa-fw"></i> 삭제
                </button> */}
              </div>
            </div>
          </form>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
